<template>
  <div class="d-flex flex-column gap-3">
    <!-- Header -->
    <organization-profile />

    <home-banners :banners="banners" />

    <!-- Content -->
    <v-row class="mx-0" dense>
      <!-- <v-col
        v-for="(item, i) in uiData"
        :key="`${item.name} + ${i}`"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <component :is="item.name" :data="item.data" />
      </v-col> -->

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="p in partiesFiltered"
        :key="p.id"
      >
        <v-card outlined rounded="lg">
          <v-img :aspect-ratio="16 / 9" :src="p.cover" />
          <div class="pt-1 px-3 pb-3">
            <h6>{{ p.name }}</h6>

            <div class="d-flex gap-3 mb-3">
              <v-tooltip v-for="btn in partyNavBtns" :key="btn.name" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="hasPermission()(btn.permission, p.id)"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    text
                    style="flex: 1"
                    :to="btn.to(p)"
                  >
                    <v-icon size="20">{{ btn.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ btn.name }}</span>
              </v-tooltip>
            </div>
            <party-quick-report
              v-if="hasPermission()(512, p.id)"
              :hide-button="true"
              :party="p"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- <cols-organizer :items="uiData" cols="1" sm="2" md="2" lg="3">
      <template v-slot="{ item }">
        <component :is="item.name" :data="item.data" />
      </template>
    </cols-organizer> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UI_SERVICE from "@/services/admin/ui";

import PartyStatus from "@/utils/partyStatus";
import PartySort from "@/utils/partySortFunction";

import OrganizationProfile from "@/components/admin/organization/home/OrganizationProfile.vue";
import NextParty from "@/components/admin/organization/home/NextParty.vue";
import TicketSold from "@/components/admin/organization/home/TicketSold.vue";
import PromoterRanking from "@/components/admin/organization/home/PromoterRanking.vue";
import RefoundRequest from "@/components/admin/organization/home/RefoundRequest.vue";
import ColsOrganizer from "../../../components/global/ColsOrganizer.vue";
import HomeBanners from "../../../components/admin/organization/home/HomeBanners.vue";
import PartyQuickReport from "../../../components/admin/party/PartyQuickReport.vue";

export default {
  components: {
    OrganizationProfile,
    NextParty,
    TicketSold,
    PromoterRanking,
    RefoundRequest,
    ColsOrganizer,
    HomeBanners,
    PartyQuickReport,
  },
  name: "Home",
  data: () => ({
    loading: false,
    uiData: [],
    banners: [],
    partyNavBtns: [
      {
        name: "Detalhes",
        icon: "mdi-eye",
        permission: 2,
        to: (party) => ({
          name: "admin.party.details",
          params: { partyId: party.id },
        }),
      },
      {
        name: "Operacional",
        icon: "mdi-cog",
        permission: [16, 64, 512],
        to: (party) => ({
          name: "admin.party.management",
          params: { partyId: party.id },
        }),
      },
      {
        name: "Relatórios",
        icon: "mdi-chart-bar",
        permission: 512,
        to: (party) => ({
          name: "admin.party.management",
          params: { partyId: party.id },
          hash: "#reports",
        }),
      },
    ],
  }),

  methods: {
    ...mapActions("organization", ["updateParties"]),
    ...mapGetters("auth", ["hasPermission", "config"]),
    async getParties() {
      try {
        this.loading = true;
        await this.updateParties();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getHomeData() {
      try {
        this.loading = true;
        const { results, banners } = await UI_SERVICE.organization.home(
          this.selectedOrganization.id
        );
        this.uiData = results;
        this.banners = banners;
      } catch (e) {
        this.error = e.message || "Ocorrera um erro ao carregar os dados";
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.uiData = [];
      this.banners = [];
      this.getHomeData();
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization", "parties"]),
    partiesFiltered() {
      return this.parties
        .reduce((acc, party) => {
          const status = new PartyStatus(party);
          if (!status.isActive) return acc;

          acc.push({
            ...party,
            isActive: status.isActive,
          });
          return acc;
        }, [])
        .sort(PartySort);
    },
  },
  mounted() {
    this.getParties();
    this.getHomeData();
  },
};
</script>

<style></style>
