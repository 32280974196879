<template>
  <div id="form-sell-ticket">
    <v-progress-linear class="mb-4" indeterminate v-if="loading" />
    <ColsOrganizer
      v-if="ticketGroups.length"
      cols="1"
      sm="2"
      lg="3"
      :items="ticketGroupsMapped"
      itemKey="id"
      :gap="1"
    >
      <template v-slot="{ item: tg }">
        <v-card outlined class="pa-4 rounded-lg">
          <div>
            <v-tooltip v-if="tg.tableGroupId" top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip small v-bind="attrs" v-on="on" class="float-right">
                  <v-icon small>mdi-table-chair</v-icon>
                  <span class="ml-1"> {{ tg.TableGroup.name }} </span>
                </v-chip>
              </template>
              <span> Lugares marcados </span>
            </v-tooltip>
            <h5 class="mb-1">{{ tg.name }}</h5>
            <DescriptionWrap :description="tg.description" :lines="2.5" />
            <v-alert
              type="info"
              color="primary"
              v-if="tg.tableGroupId && tg.TableGroup.capacity > 1"
              dense
              text
            >
              Compras neste setor dão direito
              <b class="font-weight-bold">
                {{ tg.TableGroup.capacity }} ingressos
              </b>
            </v-alert>
          </div>
          <v-card
            v-for="tb in tg.TicketBlock"
            :key="tb.id"
            :disabled="!tb.startDate || new Date(tb.startDate) > new Date()"
            elevation="0"
          >
            <v-alert
              class="mt-2 mb-2"
              :color="
                tb.startDate && new Date(tb.startDate) < new Date()
                  ? 'primary'
                  : 'secondary'
              "
              outlined
              dense
              text
            >
              <div
                class="d-flex justify-space-between align-center mb-1 gap-2 flex-wrap"
              >
                <div>
                  <h6 class="mb-0">{{ tb.name }}</h6>
                  <div class="d-flex align-center gap-2">
                    <b
                      :class="{
                        'text-18': !tb.promotion,
                        'text--disabled': tb.promotion,
                        'text-decoration-line-through': tb.promotion,
                      }"
                    >
                      {{ tb.price | currency(true) }}
                    </b>
                    <small
                      v-if="
                        !!tb.fee &&
                        tb.startDate &&
                        new Date(tb.startDate) <= new Date()
                      "
                      class="text--disabled"
                    >
                      + {{ tb.fee | currency(true) }} de taxa
                    </small>
                    <template v-if="tb.promotion">
                      <b class="text-18">
                        {{
                          calculateTicketPricePromotion(tb, tb.promotion)
                            | currency(true)
                        }}
                      </b>
                      <v-chip label color="success" small>
                        -<template
                          v-if="tb.promotion.data.discountType == 'percentage'"
                        >
                          {{ tb.promotion.data.discount + "%" }}
                        </template>
                        <template v-else>
                          {{ tb.promotion.data.discount | currency(true) }}
                        </template>
                      </v-chip>
                    </template>
                    <template v-if="tb.TicketBlockMembership.length">
                      <v-icon small color="primary">
                        mdi-card-account-details-star
                      </v-icon>
                    </template>
                  </div>
                </div>
                <div
                  v-if="!tb.startDate || new Date(tb.startDate) > new Date()"
                  class="text-end"
                >
                  <small> Em breve </small>
                </div>
                <!-- <div
                  class="d-flex flex-column text-end"
                  v-else-if="hasPermission(16)"
                >
                  <span>
                    {{ tb.tickets.remaining }} ingresso{{
                      tb.tickets.remaining != 1 ? "s" : ""
                    }}
                  </span>
                  <small
                    v-if="tb.tickets.pending != 0"
                    class="text--disabled mb-0"
                  >
                    {{ tb.tickets.pending }} pendente{{
                      tb.tickets.pending != 1 ? "s" : ""
                    }}
                  </small>
                </div> -->

                <div v-else>
                  <template>
                    <v-btn
                      v-if="party.maxTickets === 1 || !cart[tb.id]?.quantity"
                      color="primary"
                      :loading="loading === tb.id"
                      @click="buyTicket({ ticketGroup: tg, ticketBlock: tb })"
                      :disabled="
                        !!party.maxTickets &&
                        (ticketsPerGroup[tg.id]?.total >=
                          Math.min(party.maxTickets, tg.maxTickets) ||
                          partyTotal >= party.maxTickets)
                      "
                    >
                      {{ party.maxTickets === 1 ? "Comprar" : "Adicionar" }}
                    </v-btn>

                    <v-card
                      v-else
                      outlined
                      class="d-flex align-center gap-2 rounded-pill pa-1 align-self-center"
                    >
                      <v-btn icon @click="cart[tb.id].quantity--" small>
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <b class="text-center">
                        {{ cart[tb.id].quantity }}
                      </b>
                      <v-btn
                        icon
                        @click="buyTicket({ ticketGroup: tg, ticketBlock: tb })"
                        small
                        :disabled="
                          !!party.maxTickets &&
                          (ticketsPerGroup[tg.id]?.total >=
                            Math.min(party.maxTickets, tg.maxTickets) ||
                            partyTotal >= party.maxTickets)
                        "
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-card>
                  </template>
                </div>
              </div>
            </v-alert>

            <div class="d-flex"></div>
          </v-card>

          <template v-if="tg.KitList.length">
            <b class="mt-4"> Pacotes </b>
          </template>
          <div
            v-for="kit in tg.KitList"
            :key="kit.id"
            class="card-container mb-5"
            :class="{ dark: $vuetify.theme.dark }"

          >
            <v-card
              :disabled="!kit.startDate || new Date(kit.startDate) > new Date()"
              elevation="0"
              style="z-index: 3"
            >
              <v-alert
                class="mt-2 mb-0"
                :color="
                  kit.startDate && new Date(kit.startDate) < new Date()
                    ? 'primary'
                    : 'secondary'
                "
                outlined
                dense
                text
              >
                <div
                  class="d-flex justify-space-between align-center mb-1 gap-2 flex-wrap"
                >
                  <div>
                    <h6 class="mb-0">{{ kit.name }}</h6>
                    <div class="d-flex align-center gap-2">
                      <b class="text-18">
                        {{ kit.price | currency(true) }}
                      </b>
                      <small
                        v-if="
                          !!kit.fee &&
                          kit.startDate &&
                          new Date(kit.startDate) <= new Date()
                        "
                        class="text--disabled"
                      >
                        + {{ kit.fee | currency(true) }} de taxa
                      </small>
                    </div>
                  </div>
                  <div
                    v-if="
                      !kit.startDate || new Date(kit.startDate) > new Date()
                    "
                    class="text-end"
                  >
                    <small> Em breve </small>
                  </div>

                  <div v-else>
                    <template>
                      <v-btn
                        color="primary"
                        @click="buyKit({ ticketGroup: tg, kit: kit })"
                      >
                        Comprar
                      </v-btn>
                    </template>
                  </div>
                </div>
              </v-alert>

              <div class="d-flex"></div>
            </v-card>

            <div class="stack-layer" />
            <div class="stack-layer" />
          </div>
          <p
            v-if="!!party.maxTickets"
            class="mb-0 text-center text-12 text--secondary"
          >
            Limite de
            {{ Math.min(party.maxTickets, tg.maxTickets) }} ingresso{{
              Math.min(party.maxTickets, tg.maxTickets) != 1 ? "s" : ""
            }}
            por pessoa para esse setor.
          </p>
        </v-card>
      </template>
    </ColsOrganizer>
    <template v-else-if="!loading">
      <div class="text-center">
        <v-avatar color="grey lighten-4" size="50">
          <v-icon x-large class="display-1 grey darken-1"> mdi-ticket </v-icon>
        </v-avatar>
        <p class="text--disabled mt-2">
          Não há ingressos disponíveis para esse evento
        </p>
      </div>
    </template>

    <v-alert type="info" text class="mt-4">
      <div class="d-flex justify-space-between align-center flex-wrap gap-y-2">
        <div>
          <p class="mb-0">
            Compartilhe o link do evento para que outras pessoas possam comprar
            ingressos
          </p>
        </div>
        <v-btn small @click="share"> Compartilhar </v-btn>
      </div>
    </v-alert>
    <v-alert v-if="hasPermission(16)" type="info" class="mt-4">
      <div class="d-flex justify-space-between align-center flex-wrap gap-y-2">
        <div>
          <p class="mb-0">
            Você pode gerenciar os ingressos disponíveis para venda
          </p>
        </div>
        <v-btn
          small
          @click="
            $router.push({
              name: 'admin.party.management',
              params: { partyId: party.id },
              hash: '#tickets',
            })
          "
        >
          Gerenciar
        </v-btn>
      </div>
    </v-alert>

    <div class="d-flex justify-space-between align-center flex-wrap gap-y-2">
      <v-btn class="mt-2" text @click="$emit('prev')" :disabled="loading" small>
        <v-icon>mdi-chevron-left</v-icon> voltar
      </v-btn>
      <v-btn
        class="mt-2"
        text
        @click="nextStep()"
        :disabled="loading || cartTotal.quantity === 0"
        small
      >
        próximo<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <v-slide-y-reverse-transition>
      <div
        v-if="cartTotal.quantity"
        style="position: fixed; bottom: 0px; left: 0px; right: 0px; z-index: 10"
        class="d-flex justify-center pa-3"
      >
        <v-card
          elevation="24"
          class="d-flex align-center pa-3 gap-4 flex-grow-"
          rounded="xl"
          light
        >
          <v-icon>mdi-cart</v-icon>
          <span class="text-16 font-weight-medium flex-grow-1">
            {{ cartTotal.quantity }}
            ingresso{{ cartTotal.quantity != 1 ? "s" : "" }} •
            {{ cartTotal.total | currency(true) }}
          </span>
          <v-btn color="primary" @click="nextStep()"> Finalizar Compra </v-btn>
        </v-card>
      </div>
    </v-slide-y-reverse-transition>

    <buy-ticket :party="party" />
    <partyQrCode ref="qrCode" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
import DescriptionWrap from "../../../../components/global/DescriptionWrap.vue";
import ColsOrganizer from "../../../../components/global/ColsOrganizer.vue";
import BuyTicket from "../../../../components/shop/modal/BuyTicket.vue";
import { process } from "@/utils/shop/cart";
import partyQrCode from "@/components/admin/party/PartyQrCode.vue";
import party from "../../../../data/spotlight/party";

export default {
  components: { DescriptionWrap, ColsOrganizer, BuyTicket, partyQrCode },
  emit: ["input"],
  data: () => ({
    loading: false,
    ticketGroups: [],
    fees: [],
    promotions: [],
    cart: {},
    ticketGroupPanel: null,
    tableHeaders: [
      { text: "Nome", value: "name" },
      { text: "Valor", value: "price" },
      { text: "Disponíveis", value: "remaining" },
      { text: "", value: "action", sortable: false, align: "right" },
    ],
  }),
  methods: {
    nextStep() {
      this.$emit("input", this.cart);
      this.$emit("next");
    },
    async getTickets() {
      try {
        this.loading = true;
        this.$emit("input", null);
        this.ticketGroups = [];
        this.fees = [];

        const response = await TICKET.canSell(
          this.selectedOrganization.id,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
        this.promotions = response.promotions;
        this.fees = response.fees;

        if (response.ticketGroups.length == 1) this.ticketGroupPanel = 0;
      } catch (error) {
        console.log(error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    calculateFee(ticketGroup, ticketBlock) {
      if (this.fees.length === 0) return 0;

      const fee = this.fees.reduce((acc, fee) => {
        // ticketBlockId is the most specific
        if (acc?.ticketBlockId) return acc;
        if (fee.ticketBlockId && fee.ticketBlockId !== ticketBlock.id)
          return acc;

        // ticketGroupId is the second most specific
        if (acc?.ticketGroupId) return acc;
        if (fee.ticketGroupId && fee.ticketGroupId !== ticketGroup.id)
          return acc;

        return fee;
      }, null);
      if (!fee) return 0;

      const { fixed, percent } = fee.outerFee;
      const ticketPrice = ticketBlock.price;
      return fixed + (ticketPrice + fixed) * (percent / 100);
    },
    async buyTicket({ ticketBlock, ticketGroup, table }) {
      try {
        if (!this.cart[ticketBlock.id]) {
          const data = {
            ticketBlock,
            ticketGroup,
            table: table,
            quantity: 0,
          };
          this.$set(this.cart, ticketBlock.id, data);
        }
        const currentTicketBlock = this.cart[ticketBlock.id];
        this.$set(this.cart, ticketBlock.id, {
          ...currentTicketBlock,
          quantity: currentTicketBlock.quantity + 1,
        });

        if (this.party.maxTickets === 1) this.nextStep();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async buyKit(event) {
      this.$emit("selectKit", event);
      this.nextStep();
    },
    calculateTicketPricePromotion(ticketBlock, promotion) {
      if (promotion.data.discountType == "percentage")
        return (
          ticketBlock.price -
          (ticketBlock.price * promotion.data.discount) / 100
        );
      else return Math.max(0, ticketBlock.price - promotion.data.discount);
    },
    share() {
      this.$refs.qrCode.open({
        party: this.party,
        organization: this.selectedOrganization,
        seller: this.user,
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),

    mappedPromotions() {
      var ticketBlockPromotions = this.promotions.reduce((acc, promotion) => {
        promotion.data.ticketBlocks.forEach((tb) => {
          if (!acc[tb]) acc[tb] = [];
          acc[tb].push({
            ...promotion,
            price: this.calculateTicketPricePromotion(tb, promotion),
          });
        });
        return acc;
      }, {});

      return Object.fromEntries(
        Object.entries(ticketBlockPromotions).map(
          ([ticketBlock, promotions]) => {
            return [
              ticketBlock,
              promotions.sort((a, b) => a.price - b.price)[0],
            ];
          }
        )
      );
    },
    ticketGroupsMapped() {
      return this.ticketGroups.map((tg) => ({
        ...tg,
        TicketBlock: tg.TicketBlock.sort((a, b) => {
          if (!a.startDate) return 1;
          return a.price - b.price;
        }).map((tb) => ({
          ...tb,
          fee: this.calculateFee(tg, tb),
          promotion: this.mappedPromotions[tb.id],
        })),
        KitList: tg.KitList.sort((a, b) => {
          if (!a.startDate) return 1;
          return a.price - b.price;
        }).map((kit) => ({
          ...kit,
          fee: this.calculateFee(tg, kit),
        })),
      }));
    },
    ticketsPerGroup() {
      return Object.values(this.cart).reduce(
        (acc, { quantity, ticketGroup }) => {
          if (!acc[ticketGroup.id])
            acc[ticketGroup.id] = { cart: 0, user: 0, total: 0 };
          acc[ticketGroup.id].cart += quantity;
          acc[ticketGroup.id].total += quantity;
          return acc;
        },
        {}
      );
    },
    partyTotal() {
      return Object.values(this.ticketsPerGroup).reduce(
        (acc, { total }) => acc + total,
        0
      );
    },
    cartTotal() {
      return this.cartProcessed.totalValue;
    },
    cartProcessed() {
      return process(this.cart);
    },
  },

  watch: {
    party: {
      handler: function (val) {
        if (val) this.getTickets();
        else this.ticketGroups = [];
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!val) return;
        this.cart = val;
      },
      deep: true,
    },
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    party: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style lang="scss">
// Definir variáveis para as cores das sombras
$shadow-light: rgba(0, 0, 0, 0.1);
$shadow-dark: rgba(255, 255, 255, 0.02);

$background-light: #f9f9f9;
$background-dark: #333333;

// Mixin para aplicar sombra
@mixin shadow($color) {
  box-shadow: 0 2px 4px $color;
}

// Mixin para aplicar sombra de empilhamento
@mixin stack-shadow($color) {
  box-shadow: 0 4px 6px $color, 0 6px 8px $color;
}

// Estilo do Card
.card-container {
  position: relative;
  width: 100%;

  &.dark {
    .kit-card {
      background-color: $background-dark;
      @include shadow($shadow-dark);
    }

    .stack-layer {
      background-color: $background-dark;
      border: $background-dark 1px solid;
      @include stack-shadow($shadow-dark);

      &:nth-child(3) {
        background-color: darken($background-dark, 10%);
      }
    }
  }
}

// .kit-card {
//   border: 1px solid #ddd;
//   padding: 10px;
//   border-radius: 5px;
//   background-color: $background-light;
//   position: relative;
//   z-index: 3;
//   @include shadow($shadow-light);
// }

// Light
.stack-layer {
  position: absolute;
  top: 6px;
  left: 5px;
  width: 100%;
  height: 100%;
  background-color: $background-light;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: $background-light 1px solid;
  border-radius: 8px;
  z-index: 2;

  &:nth-child(3) {
    top: 11px;
    left: 10px;
    background-color: darken($background-light, 7%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
}
</style>