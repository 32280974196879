<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="5" lg="4">
        <v-card outlined class="rounded-lg">
          <v-img
            class="rounded-lg rounded-b-0"
            :src="party?.cover"
            :aspect-ratio="16 / 9"
          />
          <div class="d-flex flex-column gap-2 pa-4">
            <div class="">
              <span class="text-overline lh-1" style="">
                {{ party?.date | date("DD [de] MMMM - HH:mm") }}
              </span>
              <h4 class="text-20 mb-0">{{ party?.name }}</h4>
            </div>
            <!-- <div class="d-flex justify-space-between align-center gap-2">
              <h5 class="text-16 mb-0">{{ ticketBlock.ticketGroup.name }}</h5>
              <v-divider />
              <h5 class="text-16 text-end mb-0">{{ ticketBlock.name }}</h5>
            </div> -->
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="7" lg="8">
        <div class="d-flex flex-column gap-2 justify-space-between h-full">
          <div>
            <v-card outlined class="rounded-lg pa-4">
              <h6>Comprador</h6>
              <div outlined class="d-flex gap-2">
                <v-avatar v-if="!user?.name" size="50">
                  <v-icon x-large>mdi-account-question</v-icon>
                </v-avatar>
                <base-avatar v-else :size="50" :seed="user.id || ''" />
                <div>
                  <h6 class="mb-0">{{ user?.name || "Conta não criada" }}</h6>
                  <small>{{ user.phone }}</small>
                </div>
              </div>
            </v-card>
            <!-- <v-card
              v-if="table && "
              outlined
              class="d-flex pa-3 gap-2 mt-2 align-center rounded-lg"
            >
              <v-icon>mdi-table-chair</v-icon>
              <h6 class="mb-0 flex-grow-1">
                {{ table.group.name }} • {{ table.name }}
              </h6>
              <v-chip small class="font-weight-bold">
                {{ table.group.capacity }} pessoa{{
                  table.group.capacity > 1 ? "s" : ""
                }}
              </v-chip>
            </v-card> -->
          </div>

          <v-card-text v-if="cartProcessed.list?.length > 0" v class="px-0">
            <div class="d-flex flex-column gap-3">
              <v-card outlined class="rounded-lg pa-4">
                <div
                  v-for="(cartItem, i) in cartProcessed.list"
                  :key="i"
                  outlined
                  class="rounded-lg mx-1"
                >
                  <div
                    class="d-flex align-center justify-space-between gap-2 mb-1"
                  >
                    <p class="mb-0 lh-1 font-weight-mediu">
                      {{ cartItem.ticketGroup.name }}
                    </p>
                    <p class="mb-0 lh-1 font-weight-medium text-end">
                      {{ cartItem.ticketBlock.name }}
                    </p>
                  </div>

                  <div class="d-flex justify-space-between align-center">
                    <h6 class="mb-0 pr-2 font-weight-medium">
                      {{ cartItem.quantity }}x
                    </h6>
                    <div>
                      <h6 class="pl-3 text-end lh-1 font-weight-black mb-0">
                        <template v-if="cartItem.ticketBlock.canUseMembership">
                          <v-chip
                            :color="
                              cartItem.ticketBlock.membershipPrice[0].Membership
                                .backgroundColor
                            "
                            small
                            text-color="white"
                            label
                            class="font-weight-medium text-14 mr-3"
                            :style="{
                              background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
                                cartItem.ticketBlock.membershipPrice[0]
                                  .Membership.backgroundImg
                              }), ${
                                cartItem.ticketBlock.membershipPrice[0]
                                  .Membership.backgroundColor || '#3333'
                              }80`,
                              backgroundSize: 'cover',
                              border: `1px solid ${
                                cartItem.ticketBlock.membershipPrice[0]
                                  .Membership.backgroundColor || '#000'
                              }`,
                              boxShadow: `0 0 10px  ${
                                cartItem.ticketBlock.membershipPrice[0]
                                  .Membership.backgroundColor ||
                                'rgba(0,0,0,.2)'
                              }`,
                            }"
                          >
                            {{
                              cartItem.ticketBlock.membershipPrice[0].Membership
                                .name
                            }}
                          </v-chip>

                          <span
                            class="text-decoration-line-through text-14 text-end mr-2 font-weight-light"
                          >
                            {{ cartItem.ticketBlock.price | currency(true) }}
                          </span>
                        </template>
                        {{
                          cartItem.ticketBlock.canUseMembership
                            ? cartItem.ticketBlock.membershipPrice[0].price
                            : cartItem.ticketBlock.price | currency(true)
                        }}
                      </h6>
                      <small
                        v-if="!!cartItem.ticketBlock.fee"
                        class="text-12 text--disabled mb-0 lh-2"
                      >
                        Taxa: +
                        <b class="font-weight-medium">
                          {{ cartItem.ticketBlock.fee | currency(true) }}
                        </b>
                      </small>
                    </div>
                  </div>

                  <div
                    v-if="cartItem.ticketBlock.canUseMembership"
                    class="d-flex justify-space-between align-center mt-3"
                  >
                    <div>
                      <span
                        v-if="cartItem.biggestDiscount"
                        class="text-12 mb-0 lh-2"
                      >
                        Maior desconto possível
                      </span>
                    </div>
                    <span class="text-14 text-end mb-0 lh-1">
                      Você economizou
                      <b class="font-weight-medium">
                        {{
                          (cartItem.ticketBlock.price -
                            cartItem.ticketBlock.membershipPrice[0].price)
                            | currency(true)
                        }}
                      </b>
                    </span>
                  </div>
                  <!-- <v-alert
                    v-if="cartItem.table"
                    text
                    dense
                    type="primary"
                    class="rounded-lg mb-0 mt-1"
                  >
                    <div class="d-flex align-center gap-2">
                      <v-icon small color="primary">mdi-table-chair</v-icon>
                      <p class="mb-0 font-weight-bold text-15">
                        {{ cartItem.table.group.name }} •
                        {{ cartItem.table.name }}
                      </p>
                      <v-spacer />
                      <v-chip color="primary" text-color="white" x-small>
                        {{ cartItem.table.group.capacity }} pessoa{{
                          cartItem.table.group.capacity > 1 ? "s" : ""
                        }}
                      </v-chip>
                    </div>
                    <p class="mb-0 lh-2 text-14">
                      Essa compra dá acesso a
                      <b class="font-weight-bold">
                        {{ cartItem.table.group.capacity }} ingresso{{
                          cartItem.table.group.capacity > 1 ? "s" : ""
                        }}</b
                      >, que podem ser transferidos após o pagamento.
                    </p>
                  </v-alert> -->

                  <v-divider class="my-3" />
                </div>

                <v-card-actions
                  class="d-flex flex-column align-stretch pa-0 pt-2 mt-2"
                >
                  <template v-if="cartProcessed.totalValue.discount">
                    <div
                      class="d-flex align-center justify-space-between text-15 text--disabled"
                    >
                      <span class="mb-0 font-weight-medium">Subtotal</span>
                      <span class="mb-0 font-weight-medium">
                        {{
                          (cartProcessed.totalValue.total +
                            cartProcessed.totalValue.discount)
                            | currency(true)
                        }}
                      </span>
                    </div>
                    <div
                      class="d-flex align-center justify-space-between text-15 mb-2"
                    >
                      <span class="mb-0 font-weight-medium">Desconto</span>
                      <span class="mb-0 font-weight-medium">
                        {{
                          (cartProcessed.totalValue.discount * -1)
                            | currency(true)
                        }}
                      </span>
                    </div>
                  </template>
                  <div
                    class="d-flex align-center justify-space-between text-18 font-weight-black"
                  >
                    <span class="mb-0 lh-1">Total</span>
                    <span class="mb-0 text-22 lh-1">
                      {{ cartProcessed.totalValue.total | currency(true) }}
                    </span>
                  </div>
                </v-card-actions>

                <div class="d-flex flex-column gap-2 text-15 pt-2">
                  <v-divider />
                  <div class="d-flex align-center justify-space-between">
                    <p class="mb-0 font-weight-bold">Forma de pagamento</p>
                    <v-radio-group
                      v-model="paymentMethod"
                      row
                      class="mt-0"
                      hide-details
                    >
                      <v-radio
                        v-for="method in paymentMethods"
                        :key="method.value"
                        :value="method.value"
                        :label="method.title"
                        class="ml-4 mr-0"
                      >
                        <template v-slot:label>
                          {{ method.title }}
                          <!-- <v-chip
                        label
                        x-small
                        color="success"
                        class="ml-2"
                        v-if="
                          ticketBlock?.promotion &&
                          ticketBlock.promotion.data.paymentMethods.includes(
                            method.value
                          )
                        "
                      >
                        -<template
                          v-if="
                            ticketBlock?.promotion.data.discountType ==
                            'percentage'
                          "
                        >
                          {{ ticketBlock.promotion.data.discount + "%" }}
                        </template>
                        <template v-else>
                          {{
                            ticketBlock.promotion.data.discount | currency(true)
                          }}
                        </template>
                      </v-chip> -->
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </v-card>
            </div>
          </v-card-text>

          <v-card-text v-else-if="buyingKit" class="px-0">
            <div class="d-flex flex-column gap-3">
              <v-card outlined class="rounded-lg pa-4 pt-3">
                <h6>{{ buyingKit.kit.name }}</h6>

                <div
                  v-for="(item, i) in buyingKit.kit?.Items"
                  :key="i"
                  outlined
                  class="rounded-lg mx-1"
                >
                  <div
                    class="d-flex align-center justify-space-between gap-2 mb-1"
                  >
                    <p class="mb-0 lh-1 font-weight-mediu">
                      {{ item.TicketBlock?.TicketGroup?.name }}
                    </p>
                    <p class="mb-0 lh-1 font-weight-medium text-end">
                      {{ item.TicketBlock?.name }}
                    </p>
                  </div>

                  <div class="d-flex justify-space-between align-center">
                    <h6 class="mb-0 pr-2 font-weight-medium">
                      {{ item.quantity }}x
                    </h6>
                    <div>
                      <!-- <h6 class="pl-3 text-end lh-1 font-weight-black mb-0">
                        {{
                          cartItem.ticketBlock.canUseMembership
                            ? cartItem.ticketBlock.membershipPrice[0].price
                            : cartItem.ticketBlock.price | currency(true)
                        }}
                      </h6>
                      <small
                        v-if="!!cartItem.ticketBlock.fee"
                        class="text-12 text--disabled mb-0 lh-2"
                      >
                        Taxa: +
                        <b class="font-weight-medium">
                          {{ cartItem.ticketBlock.fee | currency(true) }}
                        </b>
                      </small> -->
                    </div>
                  </div>

                  <v-divider class="my-3" />
                </div>

                <v-card-actions
                  class="d-flex flex-column align-stretch pa-0 pt-2 mt-2"
                >
                  <div
                    class="d-flex align-center justify-space-between text-18 font-weight-black"
                  >
                    <span class="mb-0 lh-1">Total</span>
                    <span class="mb-0 text-22 lh-1">
                      {{ kitTotalValue | currency(true) }}
                    </span>
                  </div>
                </v-card-actions>

                <div class="d-flex flex-column gap-2 text-15 pt-2">
                  <v-divider />
                  <div class="d-flex align-center justify-space-between">
                    <p class="mb-0 font-weight-bold">Forma de pagamento</p>
                    <v-radio-group
                      v-model="paymentMethod"
                      row
                      class="mt-0"
                      hide-details
                    >
                      <v-radio
                        v-for="method in paymentMethods"
                        :key="method.value"
                        :value="method.value"
                        :label="method.title"
                        class="ml-4 mr-0"
                      >
                        <template v-slot:label>
                          {{ method.title }}
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </v-card>
            </div>
          </v-card-text>
        </div>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-alert type="info" color="primary" v-if="paymentMethod" border="left">
          <template v-if="paymentMethod == 'online'">
            Um link será enviado ao WhatsApp do cliente para concluir a compra.
            O link terá validade de 30 minutos para finalizar o pedido.
          </template>
          <template v-else-if="paymentMethod == 'offline'">
            <b v-if="!isBuyingKit">
              Receba
              {{ cartProcessed.totalValue.total | currency(true) }}
              agora
            </b>
            <b v-else>
              Receba
              {{ kitTotalValue | currency(true) }}
              agora
            </b>
            do cliente e finalize a venda. O ingresso será enviado
            automaticamente para o WhatsApp do cliente.
            <p class="text-14 mb-0">
              Essa venda ficará registada para acerto futuro.
            </p>
          </template>
        </v-alert>
        <v-alert type="error" text v-if="error" border="left">
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>

    <div style="width: 100%; display: flex">
      <v-btn text @click="$emit('prev')" :disabled="loading" icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="!paymentMethod"
        color="success"
        @click="finish()"
      >
        Finalizar •
        {{ !isBuyingKit ? cartTotal : kitTotalValue | currency(true) }}
      </v-btn>
    </div>
    <money-sell-confirm @close="sellMoney($event)" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
import MoneySellConfirm from "@/components/admin/modals/MoneySellConfirm.vue";
import OnlineSellModal from "@/components/admin/modals/OnlineSellModal.vue";
import { process } from "@/utils/shop/cart";
import membership from "../../../../services/admin/membership";
import validateCpf from "@/utils/validate-cpf";

export default {
  emit: ["prev"],
  components: { MoneySellConfirm, OnlineSellModal },
  data: () => ({
    error: false,
    loading: false,
    paymentMethod: null,
    document: "",
    cpfRules: [
      (v) => !!v || "CPF é obrigatório",
      (v) => validateCpf(v) || "CPF inválido",
    ],
  }),
  methods: {
    finish() {
      if (!this.isBuyingKit) {
        if (this.paymentMethod == "online") this.sell();
        else if (this.paymentMethod == "offline")
          this.confirmSellMoney({
            party: this.party,
            cart: this.cart,
            user: this.user,
            totalPrice: this.cartTotal,
          });
      } else {
        if (this.paymentMethod == "online") this.sellKit();
        else if (this.paymentMethod == "offline")
          this.confirmSellMoney({
            party: this.party,
            cart: this.cart,
            user: this.user,
            totalPrice: this.kitTotalValue,
          });
      }
    },
    confirmSellMoney(sellData) {
      this.$root.$emit("confirm-money-sell", sellData);
    },
    formatObjectCartEntries(cart) {
      return Object.fromEntries(
        Object.entries(cart)
          .map(([key, value]) => [
            key,
            {
              quantity: value.quantity,
              tables: value.tables?.map((table) => table.id) || [],
            },
          ])
          .filter(([_key, value]) => value.quantity > 0)
      );
    },

    async sellMoney(result) {
      if (!result) return;
      try {
        this.loading = true;
        this.error = false;

        if (!this.isBuyingKit) {
          const cart = this.formatObjectCartEntries(this.cart);
          await TICKET.sellMoney(this.selectedOrganization.id, this.party.id, {
            user: this.user,
            cart,
            amount: this.cartProcessed.totalValue.total,
          });
        } else {
          await TICKET.sellKitMoney(
            this.selectedOrganization.id,
            this.party.id,
            {
              user: this.user,
              kitId: this.buyingKit?.kit?.id,
              amount: this.kitTotalValue,
            }
          );
        }
        await this.confetti();
        this.$emit("reset");
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    async sell() {
      try {
        this.loading = true;
        this.error = false;
        const cart = this.formatObjectCartEntries(this.cart);
        await TICKET.sell(this.selectedOrganization.id, this.party.id, {
          user: this.user,
          cart,
          amount: this.cartProcessed.totalValue.total,
        });
        this.confetti();
        this.$emit("reset");
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async sellKit() {
      try {
        this.loading = true;
        this.error = false;
        await TICKET.sellKit(this.selectedOrganization.id, this.party.id, {
          user: this.user,
          kitId: this.buyingKit?.kit?.id,
          amount: this.kitTotalValue,
        });
        this.confetti();
        this.$emit("reset");
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    async confetti() {
      this.$confetti.start();
      await new Promise((r) => setTimeout(r, 1500));
      this.$confetti.stop();
    },
    calculateTicketPricePromotion(price, promotion) {
      if (promotion.data.discountType == "percentage")
        return price - (price * promotion.data.discount) / 100;
      else return Math.max(0, price - promotion.data.discount);
    },
    validateCPF(value) {
      if (!value) return false;
      return validateCpf(value);
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    paymentMethods() {
      var methods = [
        {
          title: "Online",
          value: "online",
          permission: 32,
        },
        {
          title: "Presencial",
          value: "offline",
          permission: 1024,
        },
      ];
      const r = methods.filter((method) =>
        this.hasPermission(method.permission)
      );
      if (r.length == 1) this.paymentMethod = r[0].value;
      return r;
    },
    membershipPrice() {
      const membershipPrice = this.membershipPlan?.price || 0;
      return membershipPrice;
    },

    cartTotal() {
      return this.cartProcessed.totalValue.total;
    },
    cartProcessed() {
      return process(this.cart);
    },
    kitTotalValue() {
      return this.buyingKit.kit?.price + this.buyingKit.kit?.fee;
    },
    isBuyingKit() {
      return !this.cartProcessed.list?.length > 0;
    },
  },
  watch: {},
  props: {
    user: {
      type: Object | null,
      required: true,
    },
    party: {
      type: Object | null,
      required: true,
    },
    cart: {
      type: Array | Object | null,
      required: true,
    },
    table: {
      type: Object | null,
      required: true,
    },
    buyingKit: {
      type: Object | null,
      required: false,
    },
  },
};
</script>

<style></style>
